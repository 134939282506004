<template>
	<v-container>
		<v-card v-if="!!resultados.triage_level">
			<v-card-title>
				<div class="text-h4 primary--text">Resultado</div>
			</v-card-title>
			<v-card-text>
				<v-alert :type="mensajes[ubicacion][resultados.triage_level].level" outlined border="left">
					<template v-slot:default>
						<div v-html="mensajes[ubicacion][resultados.triage_level].text"/>

						<div v-if="ubicacion==='remoto'">Uno de nuestros asesores se comunicará con usted en el menor tiempo posible.</div>

						<template v-if="resultados.triage_level < 3">
							<div>
								Su código para atención en clínica es: {{codigo}}
							</div>
							<div class="d-flex justify-center absolute mt-3">
								<canvas id="qrcode"/>
							</div>
						</template>
					</template>

					<template v-slot:prepend>
						<v-icon size="50" class="mr-10" :color="mensajes[ubicacion][resultados.triage_level].color">fad fa-{{mensajes[ubicacion][resultados.triage_level].icon}}</v-icon>
					</template>
				</v-alert>

				<v-alert type="info" v-if="ubicacion!=='remoto'">Esta ventana se cerrara en {{timer}} segundos</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" @click="reiniciar" dark outlined>nueva consulta</v-btn>
			</v-card-actions>
		</v-card>



	</v-container>
</template>

<script>
import * as types from "@/store/types";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
	CABINA,
	FIREBASE_ID,
	INTERVIEW_USER,
	MUTATION_DISCLAIMER, MUTATION_FIREBASE_ID,
	MUTATION_RESULTADOS,
	RESET_INTERVIEW,
	RESULTADOS, SEDE
} from "@/store/types";
import {mensajesSalida} from "../../../../messages";
import QRious from 'qrious'
import axios from "axios";
import configs from "../../../../configs.json";


export default {
	name: "ResultadosPaciente",
	data:()=>({
		cabinas: configs.cabinas,
		telefono: "",
		mensajes:mensajesSalida,
		diagnosticosDisplay: false,

		getCanalEspecialista:{
			audio_teleconsultation: "Llamada telefónica",
			video_teleconsultation: "Video llamada",
			text_teleconsultation: "Chat",
			personal_visit: "Presencial",
		},
		timer: 15
	}),
	created: async function() {
		if(process.env.NODE_ENV === 'development') {
			const docRef = await db.collection('interviews').doc('Uk070xvav4V1c6fkb2fP')
			this.$store.commit(MUTATION_FIREBASE_ID, 'Uk070xvav4V1c6fkb2fP')
			const doc = await docRef.get();
			if(doc.exists) this.$store.commit(MUTATION_RESULTADOS, doc.data())
		}

		if(process.env.NODE_ENV === 'production' && this.ubicacion !== 'remoto')
			setInterval(() => { this.timer --; }, 1000)

	},
	mounted() {

		new QRious({
			element: document.getElementById("qrcode"),
			background: '#ffffff',
			backgroundAlpha: 1,
			foreground: '#5868bf',
			foregroundAlpha: 1,
			level: 'H',
			padding: 0,
			size: 128,
			value: this.firebaseId||"No code"
		});
	},
	computed: {
		...mapGetters({
			resultados: RESULTADOS,
			ubicacion: types.UBICACION,
			interviewUser:INTERVIEW_USER,
			firebaseId: FIREBASE_ID,
			cabina: CABINA, sede:SEDE
		}),
		codigo: function(){return this.firebaseId||'sin código'}
	},
	watch:{
		timer: function (val) { if(val === 0 )this.reiniciar(true) }
	},
	methods:{
		...mapMutations({ setDisclaimer: MUTATION_DISCLAIMER }),
		...mapActions({ resetInterview: RESET_INTERVIEW }),

		reiniciar: async function(){
			this.resetInterview(true);
			this.setDisclaimer(false);
			await axios.post(`https://${this.cabinas[this.cabina]}/video/`, {"start": true});
			return this.$router.push({name:'Prepaciente', query:{ u:this.ubicacion, s: this.sede, c: this.cabinas }});
		}
	}
}
</script>

<style scoped>

</style>
