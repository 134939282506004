import template from "@/views/layouts/template";
import SignosVitales from "@/views/custom/paciente/SignosVitales";
import TestTelsit from "@/views/custom/TestTelsit";
import multiguard from "vue-router-multiguard";
import store from "@/store";
import {DISCLAIMER} from "@/store/types";
import firebase from "firebase/app";


const pacientePermission = async(to, from, next)=>{
	if(to.name ==='DisclaimerPaciente') return next();
	if(store.getters[DISCLAIMER]) return next();
	return next({name: 'DisclaimerPaciente'});
}

const clearAnonymousUser = async(to, from, next)=>{
	if(auth.currentUser?.isAnonymous) {
		await firebase.auth().currentUser.delete();
		await firebase.auth().signOut()
	}
	await auth.signInAnonymously();
	return next();
}

const CustomRoutes = [
	{ path: '/custom', component: template,
		children: [
			{ path: '/signosvitales', name: 'SignosVitales', component: SignosVitales, beforeEnter: multiguard([pacientePermission]) },
			{ path: '/testtelsit', name: 'TestTelsit', component: TestTelsit, beforeEnter: multiguard([clearAnonymousUser]) }
		]}
];

export default CustomRoutes;
