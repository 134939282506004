<template>
	<v-container>
		<v-card>
			<v-card-title>
				<div class="text-h4 primary--text">Toma de signos vitales</div>
			</v-card-title>
			<v-card-text>
				<div class="text-h6">
					Por favor, presiona el botón para iniciar la toma de signos vitales.
				</div>
				<v-row>
					<v-col class="d-flex flex-column mt-5" v-if="esperandoRespuesta">
						<div class="mx-auto">Esperando resultados de los signos vitales</div>
						<v-progress-circular  class="mx-auto"
							indeterminate
							color="primary"
						/>
					</v-col>
					<v-col class="d-flex justify-center mt-5" v-else>
						<v-btn class="" outlined @click="iniciar">iniciar</v-btn>
					</v-col>
				</v-row>
			</v-card-text>

		</v-card>



	</v-container>
</template>

<script>
import configs from '../../../../configs.json'
import {mapGetters} from "vuex";
import {CABINA, FIREBASE_ID} from "@/store/types";
import axios from "axios";
export default {
	name: "SignosVitales",

	data:()=>({
		cabinas: configs.cabinas,
		interviewData: null,
		esperandoRespuesta: false,
		params: {
			interview: null,
			riskFactors: null
		}
	}),
	computed:{
		...mapGetters({cabina: CABINA, firebaseId: FIREBASE_ID})
	},
	created: async function() {
		this.params = {
			interview: this.$route.params.interview,
			riskFactors: this.$route.params.riskFactors
		}

		db.collection('interviews').doc(this.firebaseId).onSnapshot( doc =>{
			this.interviewData = doc.data()
		})
	},
	watch: {
		interviewData: function(val){
			if( val.signosVitales ) {
				this.$router.push({
					name: 'Interview',
					params: this.params
				})
			}
		}
	},
	methods:{
		iniciar(){
			this.esperandoRespuesta = true;
			console.log(`https://${this.cabinas[this.cabina]}`);
			axios.post(`https://${this.cabinas[this.cabina]}/`, {"start": true, interview_id: this.firebaseId});
		}
	}


}
</script>

<style scoped>

</style>
